#mywishlist{
	@media(max-width:520px){
		th:nth-child(2),
		td:nth-child(2),
		th:nth-child(3),
		td:nth-child(3),
		th:nth-child(4),
		td:nth-child(4),
		th:nth-child(5),
		td:nth-child(5){
			display: none;
		}
	}
}